import React, { useEffect } from 'react'

import Layout from '../../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import Form from '../../components/forms/Form'

import HeaderSEO from '../../components/partials/HeaderSEO'
import { getEmailLocalStorage } from '../../helpers/getEmailStorage'

import { getCookieByName } from '../../helpers/getCookieByName'
import { initializeVideos } from '../../helpers/initializeVideo'

const celonisKafkaConnector = () => {
  const data = useStaticQuery(graphql`
    query FORM_EMS_CONNECTOR_SEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "FORM_EMS_CONNECTOR" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)
  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    // For Defering Vimeo videos
    initializeVideos()
    // Zoominfo Snippet
    let emailStorage = getEmailLocalStorage()
    if (emailStorage) {
      document.querySelector<HTMLInputElement>('#email').value = emailStorage
    }
  }, [])

  const handleFormSubmit = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>(
      '#zoominfo-default-form'
    ).disabled = true
    sessionStorage.setItem('Timestamp', new Date().toLocaleString())
  }
  return (
    <Layout onlyLogo={true}>
      <HeaderSEO pageSEO={pageSEO} />

      <div className="container-1 hero-margin">
        <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
          <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
            <h1 className="lh-2-3 text-center">
              Get the free Kafka to Celonis connector
            </h1>
            <div className="w-75 mx-auto mt-4">
              <h2 className="text-center my-2 fw-400 f-20 lh-32">
                The Celonis Kafka connector sinks data from your Kafka Topics
                straight to the Celonis Execution Management System (EMS).
              </h2>
            </div>
            <div className="cloud-aws-msk-video">
              <iframe
                src=""
                data-src="//player.vimeo.com/video/623457154"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <section className="mobile-margin-top">
        <div className="container-1 pt-5 mb-4 mobile-padding-reset">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-5 col-10 col-md-9 pt-5">
              <p className="hero-description-dark pt-5 mobile-padding-reset">
                The Celonis Kafka connector is the first of its kind to send
                streaming data into your Execution Management System - from
                ensuring on-time delivery of your orders with live location
                tracking to real-time process-mining of your latest digital
                services.
              </p>
              <p className="hero-description-dark">
                Be among the first to sink streaming data to your EMS:
              </p>
              <ul>
                <li>Fill out the form</li>
                <li>
                  Someone from our team will contact you and provide access to
                  the connector
                </li>
                <li>
                  Follow the{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/lensesio/kafka-celonis-ems-connector/wiki"
                  >
                    documentation
                  </a>{' '}
                  to set it up
                </li>
                <li>Start streaming real-time data to your EMS</li>
              </ul>
            </div>

            <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                    <Form
                      formId="zoominfo-default-form"
                      action="https://go.pardot.com/l/877242/2021-10-07/8h51md"
                      onSubmit={handleFormSubmit}
                      inputId="formsubmit"
                      inputValue="Submit Request"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                      message={'required'}
                      messageValue={'Contact us message'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default celonisKafkaConnector
